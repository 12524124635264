<template>
  <RevModal
    :name="props.name"
    :title="i18n(translations.title)"
    variant="panel"
    @open="handleModalOpen"
  >
    <template #body>
      <RevIllustration
        :alt="ONEY_GROUP_LABEL"
        class="mx-auto my-56 flex"
        :height="140"
        src="/img/payment/installment-card.svg"
        :width="183"
      />

      <h3 class="heading-3 text-center">
        {{ i18n(translations.subtitle) }}
      </h3>

      <RevIllustration
        v-if="ONEY_GROUP_ICON"
        :alt="ONEY_GROUP_LABEL"
        class="mx-auto mt-8 block"
        :height="20"
        :src="ONEY_GROUP_ICON"
        :width="85"
      />

      <div v-if="hasManyOneyPaymentMethods" class="mt-32 flex flex-wrap gap-12">
        <RevPicker
          v-for="(paymentMethod, index) in oneyPaymentMethods"
          :key="paymentMethod.bmCode"
          class="mb-2 min-w-[calc(50%-1.2rem)] flex-1 first:mx-0 even:mb-2 even:ml-3"
          :data-qa="paymentMethod.bmCode"
          :label="paymentMethodLabel(paymentMethod.bmCode)"
          :selected="index === selectedPaymentMethodIndex"
          @select="handleMethodPickerSelect(index)"
        />
      </div>

      <InstallmentSimulation
        v-if="selectedPaymentMethod"
        :base-price="props.basePrice"
        class="mt-24"
        :payment-method="selectedPaymentMethod"
      />

      <div v-if="hasCreditNotice" class="mt-6">
        {{ i18n(translations.creditNotice) }}
      </div>

      <div
        class="border-static-default-low rounded-md mt-56 flex flex-col gap-32 border p-24"
      >
        <div v-for="step in STEPS" :key="step.title.id">
          <div class="body-2-bold">
            {{ i18n(step.title) }}
          </div>
          <div class="body-2 mt-8">
            {{ i18n(step.description) }}
          </div>
        </div>
      </div>

      <div class="caption">
        <FormattedMessage
          v-if="legalNotices"
          class="mb-32 mt-24"
          :definition="legalNotices.definition"
          tag="p"
        >
          <template
            v-for="translationKey in Object.keys(legalNotices.values)"
            #[translationKey]
            :key="translationKey"
          >
            <strong>{{ legalNotices.values[translationKey] }}</strong>
          </template>

          <template
            v-for="linkTranslationKey in Object.keys(legalNotices.links || {})"
            #[linkTranslationKey]
            :key="linkTranslationKey"
          >
            <RevLink
              target="_blank"
              :to="legalNotices.links[linkTranslationKey].url"
            >
              {{ i18n(legalNotices.links[linkTranslationKey].definition) }}
            </RevLink>
          </template>
        </FormattedMessage>
      </div>
    </template>

    <template #trigger="slotProps">
      <slot v-bind="slotProps" name="trigger" />
    </template>
  </RevModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { paymentAPI } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { RevModal } from '@ds/components/Modal'
import { RevPicker } from '@ds/components/Picker'

import { usePaymentFormVersion } from '../../../form-common/composables/usePaymentFormVersion'
import {
  paymentGroupConfig,
  paymentGroupIcon,
  paymentGroupLabel,
  paymentMethodLabel,
  usePaymentMethodsWithFallback,
} from '../../../methods'
import { type PaymentAdvertisingModalProps } from '../../types/PaymentAdvertisingModalProps'
import InstallmentSimulation from '../InstallmentSimulation'

import translations from './OneyModal.translations'

const props = withDefaults(defineProps<PaymentAdvertisingModalProps>(), {
  name: 'OneyModal',
  paymentMethods: null,
})

const i18n = useI18n()
const { market } = useMarketplace()

const ONEY_GROUP_LABEL = paymentGroupLabel('oney')
const ONEY_GROUP_ICON = paymentGroupIcon('oney')

const STEPS = [
  {
    title: translations.step1Title,
    description: translations.step1Description,
    withIcon: true,
  },
  {
    title: translations.step2Title,
    description: translations.step2Description,
    withIcon: true,
  },
  {
    title: translations.step3Title,
    description: translations.step3Description,
    withIcon: false,
  },
]

const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)

const oneyPaymentMethods = computed(() =>
  paymentMethods.value.filter(
    (paymentMethod) => paymentMethod.group === 'oney',
  ),
)
const hasManyOneyPaymentMethods = computed(
  () => oneyPaymentMethods.value.length > 1,
)
const selectedPaymentMethodIndex = ref(-1)
const selectedPaymentMethod = computed(
  () => oneyPaymentMethods.value[selectedPaymentMethodIndex.value] || null,
)
const selectedPaymentMethodConfig = computed(
  () =>
    selectedPaymentMethod.value?.config as paymentAPI.OneyPaymentMethodConfig,
)

const formVersion = usePaymentFormVersion()
const paymentMethodStaticConfig = computed(() =>
  paymentGroupConfig('oney', {
    methods: oneyPaymentMethods.value,
    formVersion,
  }),
)

const legalNotices = computed(
  () =>
    paymentMethodStaticConfig.value.legalNotices?.[market.countryCode] || null,
)

const hasCreditNotice = computed(() =>
  Boolean(selectedPaymentMethodConfig.value?.legal?.creditNotice),
)

function handleModalOpen() {
  selectedPaymentMethodIndex.value = 0
}

function handleMethodPickerSelect(index: number) {
  selectedPaymentMethodIndex.value = index
}
</script>
