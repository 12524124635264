import { MarketCountryCode } from '@backmarket/http-api'

// https://developer.paypal.com/limited-release/sdk-pay-later-messaging-cross-border/#eligibility
export const PAYPAL_PAY_LATER_AVAILABLE_COUNTRIES = [
  MarketCountryCode.AU,
  MarketCountryCode.DE,
  MarketCountryCode.ES,
  MarketCountryCode.FR,
  MarketCountryCode.GB,
  MarketCountryCode.IT,
  MarketCountryCode.US,
]
