import { useScriptTag } from '#imports'

import { paymentAPI } from '@backmarket/http-api'

import { isValidKlarnaPaymentMethodConfig } from '../../../form-common/helpers/isValidConfig'
import { PaymentMethodMisconfiguredError } from '../../../form-common/types/PaymentMethodMisconfiguredError'

export function useKlarnaLibrary(
  klarnaPaymentMethod: paymentAPI.PaymentMethod,
  onLoaded?: Parameters<typeof useScriptTag>[1],
  options?: Parameters<typeof useScriptTag>[2],
) {
  if (!isValidKlarnaPaymentMethodConfig(klarnaPaymentMethod.config)) {
    throw new PaymentMethodMisconfiguredError(
      'Invalid Klarna payment method configuration',
    )
  }
  const { libraryUrl, clientId, environment } = klarnaPaymentMethod.config

  return useScriptTag(libraryUrl, onLoaded, {
    ...options,
    attrs: {
      'data-environment': environment ?? '',
      'data-client-id': clientId,
      ...options?.attrs,
    },
  })
}
